
import { defineComponent } from 'vue'

export default defineComponent({
  name: 'AppAlert',

  props: {
    show: {
      type: Boolean,
      default: false,
    },
  },
})


import { computed, defineComponent } from 'vue'

export default defineComponent({
  name: 'Result',

  props: {
    status: {
      type: String,
      default: '',
    },
    correctWord: {
      type: String,
      default: '',
    },
  },

  setup(props, { emit }) {
    const resultMsg = computed(() => {
      if (props.status === 'won')
        return 'Congratulations! You have guessed the word!'
      if (props.status === 'lost')
        return 'You were not able to guess the word...'
      return ''
    })

    const restart = () => emit('restart')

    return { resultMsg, restart }
  },
})

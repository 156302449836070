
import { defineComponent } from 'vue'

export default defineComponent({
  name: 'WrongGuessedLetters',

  props: {
    wrongLetters: {
      type: Array,
      default: () => [],
    },
  },
})

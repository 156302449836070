
import { defineComponent } from 'vue'

export default defineComponent({
  name: 'HangMan',

  props: {
    amtWrong: {
      type: Number,
      default: 0,
    },
  },

  setup(props) {
    // Returns true if the paramater given is smaller than the equal amount wrong
    const lessOrEq = (num: number) => props.amtWrong >= num

    return { lessOrEq }
  },
})

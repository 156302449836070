<template>
  <h1>Vue Hangman</h1>
  <p>Find the hidden word - Enter a letter</p>
</template>

<script lang="ts">
import { defineComponent } from 'vue'

export default defineComponent({
  name: 'AppHeader',
})
</script>


import { defineComponent } from 'vue'

export default defineComponent({
  name: 'CorrectWord',

  props: {
    letters: {
      type: Array,
      required: true,
    },
    correctLetters: {
      type: Array,
      default: () => [],
    },
  },
})
